import type { UserExtendedView } from "@wind/user";

import { useOptionalTypedRouteLoaderData } from "./useTypedRouteLoaderData.js";

export const useOptionalUser = () => {
  const sRoot = useOptionalTypedRouteLoaderData("routes/s");
  const adminRoot = useOptionalTypedRouteLoaderData("routes/admin");
  const acceptRoot = useOptionalTypedRouteLoaderData("routes/accept.$inviteId");

  return sRoot?.user ?? adminRoot?.user ?? acceptRoot?.user;
};

export const useUser = (): UserExtendedView => {
  const user = useOptionalUser();
  if (!user) {
    throw new Error("No user not logged in");
  }
  return user;
};
